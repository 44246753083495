import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const H3HeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    marginTop: [40],
    marginBottom: [16],
    letterSpacing: [0.25],
    textAlign: ['left'],
    fontSize: [33.6, 33.6, 33.6, 47.9],
    fontWeight: [600, 600, 600, 500]
  })
)

export const PHeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    textAlign: ['left']
  })
)

export const header = css`
  display: flex;
  text-align: center;
  h3 {
    ${H3HeaderBps};
    color: rgba(0, 0, 0, 0.87);
  }
  p {
    ${PHeaderBps};
    margin-bottom: 16px;
  }
`
export const styleStructure = css`
  background-color: #f3f5f5;
`